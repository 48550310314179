// @ts-nocheck

import { ComponentProps, ElementType } from 'react';
import { SxProps, Theme, Typography } from '@mui/material';
import { cn } from 'src/utils';

import textColors from './config/colors';
import sizes from './config/sizes';
import { weights } from './config/weight';
import { FontSize } from './types/FontSize';

interface Props extends ComponentProps<typeof Typography> {
  size?: FontSize;
  color?: keyof typeof textColors;
  weight?: keyof typeof weights;
  children: React.ReactNode;
  withMonospace?: boolean;
  sx?: SxProps<Theme>;
  as?: ElementType<any>;
}

/**
 *
 * @param size - xs: 12px, sm: 14px, base: 16px, lg: 18px, xl: 20px, 2xl: 24px, 3xl: 30px, 4xl: 36px, 5xl: 48px, 6xl: 64px, 7xl: 72px, 8xl: 96px, 9xl: 128px
 * @param color - primary: black, secondary: neutral.800, tertiary: neutral.600
 * @param weight
 * @param children
 * @param sx
 * @returns
 * @category Primitives
 * @module Text
 * @example
 * <Text size="base" color="black" weight="regular">
 */

export default function Text({
  size = 'base',
  color = 'primary',
  weight = 'regular',
  children,
  withMonospace,
  as,
  sx,
  ...props
}: Props) {
  return (
    <p
      {...props}
      className={cn('!text-ds-text-primary', props.className)}
      component={as || 'p'}
      style={{
        color: textColors[color] as string,
        fontSize: sizes[size].fontSize + 'px',
        lineHeight: sizes[size].lineHeight + 'px',
        fontWeight: weights[weight],
        fontFamily: withMonospace
          ? "'Roboto Mono', monospace"
          : "var(--font-helvetica), 'Inter', 'Red Hat Display', 'sans-serif'",
        ...sx,
        marginBottom: props.mb ? props.mb.toString() : undefined,
        marginLeft: props.ml ? props.ml.toString() : undefined,
        marginTop: props.mt ? props.mt.toString() : undefined,
        marginRight: props.mr ? props.mr.toString() : undefined,
        ...props.style,
      }}
    >
      {children}
    </p>
  );
}
