import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import SplashScreen from 'components/SplashScreen';
import { removeStorageData, setStorageData } from 'utils/storage';
import { getErrorMessage } from 'utils/typescript/getErrorMessage';
import { acceptInvitationByUniqueId } from 'services/teamManagement';
import { useAppStateContext } from '../store/AppProvider';
import { useTeamStore } from '../store/team/teamStore';
export const INVITE_ID_STORAGE_KEY = 'inviteId';
export default function JoinTeamPage() {
  const effectRan = useRef(false);
  const router = useRouter();
  const {
    user
  } = useAppStateContext();
  const {
    setTeamAndProject
  } = useTeamStore();
  const inviteTokenFromQuery = router.query.inviteToken;
  useEffect(() => {
    const handleJoinTeam = async () => {
      if (effectRan.current) return;
      if (!inviteTokenFromQuery || typeof inviteTokenFromQuery !== 'string') {
        await router.replace('/');
        return;
      }
      const isAuthenticated = user.data;
      if (!isAuthenticated) {
        setStorageData(INVITE_ID_STORAGE_KEY, inviteTokenFromQuery);
        router.replace('/?login=true');
        return;
      }
      try {
        const response = await acceptInvitationByUniqueId(inviteTokenFromQuery);
        if (response.team && response.project) {
          setTeamAndProject(response.team, response.project);
          toast.success(response.message);
        }
      } catch (e) {
        toast.error('Error while exporting report');
      } finally {
        // remove localStorage if there is any
        removeStorageData(INVITE_ID_STORAGE_KEY);
        await router.replace('/');
      }
    };
    handleJoinTeam();
    return () => {
      effectRan.current = true;
    };
  }, [router]);
  return <SplashScreen />;
}