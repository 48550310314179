import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { toast } from 'sonner';
import { useAppStateContext } from 'src/store/AppProvider';
import { useTeamStore } from 'src/store/team/teamStore';
import { mutate } from 'swr';
import * as yup from 'yup';

import { InputField } from 'components/Form';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import { getErrorMessage } from 'utils/typescript/getErrorMessage';
import { editTeamName } from 'services/teamManagement';
import { TeamResItem, useGetTeams } from 'services/teamManagement/useGetTeams';

const teamNameFormSchema = yup.object({
  name: yup
    .string()

    .min(3)
    .max(30, 'Team name can contain a maximum of 30 characters')
    .matches(/^[a-zA-Z0-9\s]*$/, 'Name contains invalid characters')
    .optional(),
});

export default function RegisterView() {
  const router = useRouter();

  const { team: selectedTeam, setTeamAndProject } = useTeamStore();
  const { user } = useAppStateContext();

  const { teams } = useGetTeams();

  const form = useForm<yup.InferType<typeof teamNameFormSchema>>({
    resolver: yupResolver(teamNameFormSchema),
    mode: 'onChange',
  });

  const createOrUpdateOrganizationInCrm = async (teamName: string) => {};

  const onSubmit = async (data: yup.InferType<typeof teamNameFormSchema>) => {
    try {
      const teamName = data.name;

      const team = getFirstUserOwnedTeam({ teams, user });

      let pathname = router.pathname;

      if (teamName && !!team) {
        // step 1 - edit team name, as it's already created
        const editedTeam = await editTeamName(team.id, teamName);
        await mutate('/api/teamManagement');

        const _newTeams = await fetch('/api/teamManagement');
        const newTeams = await _newTeams.json();

        const thisTeam = newTeams.teams.find((t: any) => t.id === team.id);
        const project = thisTeam?.projects[0];

        if (thisTeam && project) {
          setTeamAndProject(thisTeam, project);

          const isPopup = typeof router.query.register !== 'undefined';

          router.query.initial = 'true';
          isPopup && delete router.query.register;

          if (router.query.teamSlug && router.query.projectSlug) {
            router.query.teamSlug = thisTeam.slug;
            router.query.projectSlug = project.slug;
          }

          await createOrUpdateOrganizationInCrm(teamName);

          router.query.initial = 'true';
          router.query['customer-success-modal'] = 'true';

          await router.push(router);
          router.reload();
        }
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleSkip = async () => {
    const isPopup = typeof router.query.register !== 'undefined';
    isPopup && delete router.query.register;

    const team = getFirstUserOwnedTeam({ teams, user });

    if (team) {
      await createOrUpdateOrganizationInCrm(team.name);
    }

    await router.push(router);
    router.reload();
  };

  return (
    <>
      <Box>
        <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
          <VStack fullHeight fullWidth vcenter px={5} gap={4} py={3.5}>
            <Text weight="medium" size="2xl">
              Hi 👋, welcome to Cookie3!
              <br />
              Let&apos;s get you started
            </Text>
            <Box>
              <Text mb={1} size="md" color="secondary">
                How should we call your team?
              </Text>
              <InputField
                autoFocus
                control={form.control}
                maxLength={30}
                showCounter
                inputLength={form.watch('name')?.length}
                placeholder="Enter your team's name"
                fieldName={'name'}
                helperText="You can skip this step and we'll generate a random name for you"
              />
            </Box>
            <HStack gap={3}>
              <Button
                size="small"
                fullWidth
                variant="outline"
                onClick={handleSkip}
              >
                Skip for now
              </Button>
              <Button
                size="small"
                fullWidth
                isLoading={form.formState.isSubmitting}
                type="submit"
                disabled={
                  !form.formState.isValid ||
                  form.watch('name') === '' ||
                  !form.formState.isDirty
                }
              >
                Continue
              </Button>
            </HStack>
          </VStack>
        </form>
      </Box>
    </>
  );
}

const getFirstUserOwnedTeam = ({
  teams,
  user,
}: {
  teams: TeamResItem[];
  user: ReturnType<typeof useAppStateContext>['user'];
}) => {
  const userEmail = user?.data?.email;
  if (!userEmail) return undefined;

  const ownedTeams = teams.filter(
    (team) => !team.isDemo && team.owner === userEmail,
  );

  return ownedTeams.length > 0 ? ownedTeams[0] : undefined;
};
