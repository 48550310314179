import { PropsWithChildren } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import isProduction from 'utils/isProduction';
import isProductionEnv from 'utils/isProductionEnv';

if (typeof window !== 'undefined') {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
    debug: false, // or 'always' to create profiles for anonymous users as well
    loaded: function (posthog) {
      if (!isProduction()) {
        posthog.debug(false);
      }
    },
  });
}

export default function Posthog(props: PropsWithChildren<{}>) {
  return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>;
}
