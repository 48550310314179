import { useState } from 'react';
import { PopupButton, PopupModal, PopupWidget } from 'react-calendly';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { useIsLoggedIn } from 'src/store/AppProvider';

import Modal from 'common/Modal';
import Button from 'components/primitives/Button';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import { Dialog, DialogContent } from 'components/ui/dialog';

import useCalendlyModal from '../hooks/useCalendlyModal';
import useRegistrationSuccessModal from '../hooks/useRegistrationSuccessModal';

export default function RegistrationSuccessModal() {
  const isLoggedIn = useIsLoggedIn();
  const { close, isOpen, open } = useRegistrationSuccessModal();
  const { open: openCaledlyModal } = useCalendlyModal();

  const router = useRouter();

  const handleOpenCaledlyPopup = () => {
    close();
    openCaledlyModal();
  };

  return (
    <Dialog open={isOpen && isLoggedIn} onOpenChange={close}>
      <DialogContent>
        <VStack alignItems="center">
          <Text size="2xl" weight="medium">
            Your account is ready!
          </Text>
          <Text size="2xl" weight="bold" mb={3}>
            You can now access the platform
          </Text>
          <Text size="sm" weight="medium" mb="5px">
            Do you have questions or need onboarding?
          </Text>
          <Text size="xs" mb={3}>
            Select one of the people to choose a date for the meeting.
          </Text>
          <Button
            onClick={handleOpenCaledlyPopup}
            variant="light"
            fullWidth
            sx={{ marginBottom: 3 }}
          >
            Setup an onboarding call
          </Button>

          <Button
            fullWidth
            onClick={() => {
              delete router.query.initial;
              close();
            }}
          >
            Go to the platform
          </Button>
        </VStack>
      </DialogContent>
    </Dialog>
  );
}
