import { Toaster } from 'components/ui/sonner';

interface Props {
  children: React.ReactNode;
}

export default function WithSnackbar({ children }: Props) {
  return (
    <>
      <Toaster />
      {children}
    </>
  );
}
