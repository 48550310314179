import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { toast } from 'sonner';

import CheckIcon from 'theme/icons/check';
import useLogin from 'utils/hooks/useLogin';
import supabase from 'lib/supabase';
import providers from 'lib/supabase/oauth/config';
import { LoginStep } from 'types/Login';
import { LoginFormData } from 'types/Login/form';

import EmailLoginForm from './EmailLoginForm';
import SocialLoginButtons from './SocialLoginButtons';
import { loginFormSchema } from './utils/formSchema';
import VerifyOptInputModal from './VerifyOtpInputModal';

interface Props {
  isValidating?: boolean;
  isRedirecting?: boolean;
}

export default function LoginForm({ isValidating, isRedirecting }: Props) {
  const { onSocialLogin, onEmailLogin, loginState, setLoginState } = useLogin();

  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [otpInput, setOtpInput] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [shouldVerify, setShouldVerify] = useState(true);
  const [otpVerificationError, setOtpVerificationError] = useState<any | null>(
    null,
  );

  const router = useRouter();

  const onSubmit = async (data: LoginFormData) => {
    try {
      setIsOtpModalOpen(true);
      setIsOtpLoading(true);
      await onEmailLogin(data.email);
      setIsOtpLoading(false);
    } catch (e) {
      console.log(e);
      setLoginState(LoginStep.READY);
    }
  };

  const isLoading = !!isValidating || !!isRedirecting;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginFormSchema),
    shouldUnregister: true,
    mode: 'onChange',
  });

  useEffect(() => {
    async function verifyEmail(otpInput: string) {
      try {
        setVerifying(true);
        const verification = await supabase.auth.verifyOtp({
          email: watch('email'),
          token: otpInput,
          type: 'email',
          options: {
            redirectTo: `${window.location.origin}/api/login/callback`,
          },
        });

        if (verification.data.session?.access_token)
          await router.push('/api/login');
        // await authenticateWithServer(verification.data.session.access_token);
        else throw new Error('Invalid OTP');
      } catch (e) {
        console.log(e);
        setOtpVerificationError(e);
        setVerifying(false);
        setLoginState(LoginStep.READY);
      }
    }
    if (otpInput.length === 6 && shouldVerify) {
      setShouldVerify(false);
      verifyEmail(otpInput);
    }
  }, [otpInput, router, setLoginState, watch, shouldVerify]);

  const renderLoginStep = () => {
    if (isValidating)
      return (
        <>
          <CircularProgress size={16} />
          <Box ml={1}>Validating...</Box>
        </>
      );

    if (isRedirecting)
      return (
        <>
          <CircularProgress size={16} />
          <Box ml={1}>Redirecting...</Box>
        </>
      );

    switch (loginState) {
      case LoginStep.READY:
        return 'Log in / Sign up';
      case LoginStep.LOADING:
        return 'Log in / Sign up';
      case LoginStep.EMAIL_SENT:
        return <CheckIcon color="green" />;
      case LoginStep.EMAIL_NOT_DELIVERABLE:
        return 'Email not deliverable. Try again!';
      case LoginStep.LOGGING_IN:
        return 'Logging in...';
      case LoginStep.ERROR:
        return 'Error';
      default:
        return 'Log in / Sign up';
    }
  };

  const email = watch('email');
  return (
    <>
      <Box
        py={0}
        px={{ xs: 2, md: 6 }}
        sx={{
          borderRadius: '20px !important',
          width: '100%',
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        overflow="hidden"
      >
        <h3 className="text-3xl text-ds-text-primary">Hi! 👋</h3>
        <h4 className="mt-2 text-4xl font-medium text-ds-text-primary">
          Welcome to Cookie3
        </h4>
        <Box mt={4}>
          <EmailLoginForm
            control={control}
            buttonContent={renderLoginStep()}
            disabled={
              Object.keys(errors).length > 0 ||
              loginState !== 'READY' ||
              isLoading
            }
            isLoading={isLoading}
            onSubmit={handleSubmit(onSubmit)}
          />
        </Box>
        <>
          <Divider
            className="*:border-ds-border-primary *:text-ds-text-primary"
            sx={{ my: 1.5, fontWeight: 600 }}
          >
            or
          </Divider>
          <SocialLoginButtons
            loginProviders={providers}
            onClick={onSocialLogin}
          />
        </>

        <Typography
          className="text-ds-text-tertiary"
          sx={{ fontSize: '12px' }}
          mt={6}
        >
          By logging in, you agree to our{' '}
          <a
            href="https://cookie3.co/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="https://cookie3.co/terms"
            target="_blank"
            rel="noreferrer noopener"
          >
            Terms of Service
          </a>
          .
        </Typography>
        <VerifyOptInputModal
          key="otp-modal"
          isOpen={isOtpModalOpen}
          error={otpVerificationError}
          isLoading={isOtpLoading}
          email={email}
          close={() => {
            setIsOtpModalOpen(false);
            setOtpInput('');
            setVerifying(false);
            setOtpVerificationError(null);
            setShouldVerify(true);
            setLoginState(LoginStep.READY);
            toast.info('Login cancelled, please try again');
          }}
          open={() => {
            setIsOtpModalOpen(true);
          }}
          toggle={() => {}}
          otpInput={otpInput}
          setOtpInput={setOtpInput}
        />
      </Box>
    </>
  );
}
