import React from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'src/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isDirty?: boolean;
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  invalid?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      disabled,
      invalid,
      leftIcon,
      rightIcon,
      isDirty,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          'relative flex h-12 w-full items-center gap-2 rounded-ds_s border border-ds-border-tertiary bg-ds-tertiary px-3 py-3 text-ds_m text-ds-text-primary transition-colors',
          '[&>input]:placeholder:text-ds-text-tertiary',
          'focus-within:border-ds-border-focus focus-within:outline-none focus-within:ring-4 focus-within:ring-[#314CFF]/25',
          'file:border-0 file:bg-transparent file:text-sm file:font-medium',
          className,
          disabled &&
            'cursor-not-allowed bg-ds-quarternary text-ds-text-quarternary',
          isDirty && 'border-border-error',
          {
            'border-ds-border-error focus-within:border-ds-border-error focus-within:ring-4 focus-within:ring-[#ff534040]':
              invalid,
          },
        )}
      >
        {leftIcon && (
          <div className="text-fg-secondary shrink-0">{leftIcon}</div>
        )}
        <input
          ref={ref}
          className={cn(
            'w-full bg-transparent file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-0',
          )}
          disabled={disabled}
          {...props}
        />
        <div className="text-fg-secondary shrink-0">{rightIcon}</div>
      </div>
    );
  },
);

Input.displayName = 'Input';

export interface InputCounterProps extends InputProps {}

const InputCounter = React.forwardRef<HTMLInputElement, InputCounterProps>(
  ({ className, disabled, isDirty, ...props }, ref) => {
    const form = useFormContext();

    return (
      <div
        className={cn(
          'relative flex h-12 w-full items-center gap-2 rounded-ds_s border border-ds-border-tertiary bg-ds-secondary text-sm text-ds-text-primary transition-colors',
          '[&>input]:placeholder:text-ds-text-tertiary',
          'focus-within:outline-none focus-within:ring-1 focus-within:ring-[#314CFF]/25',
          'file:border-0 file:bg-transparent file:text-sm file:font-medium',
          className,
          disabled &&
            'cursor-not-allowed bg-ds-quarternary text-ds-text-quarternary',
          isDirty && 'border-border-error',
        )}
      >
        <div className="grid h-full place-items-center border-r border-ds-border-tertiary">
          <button
            onClick={() => {
              if (!form) {
                throw new Error(
                  'InputCounter must be used within a FormProvider',
                );
              }

              form.setValue(
                props.name!,
                (form.getValues(props.name!) || 0) - 1,
              );
            }}
            className="bg-ds-button-bg-ds-default default mx-1 h-10 w-10 rounded-lg text-ds-text-primary"
          >
            -
          </button>
        </div>

        <input
          ref={ref}
          className={cn(
            'h-full w-full bg-transparent px-3 py-1 text-center [appearance:textfield] file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
          )}
          disabled={disabled}
          {...props}
          type="number"
        />
        <div className="grid h-full place-items-center border-l border-ds-border-tertiary">
          <button
            onClick={() => {
              if (!form) {
                throw new Error(
                  'InputCounter must be used within a FormProvider',
                );
              }

              form.setValue(
                props.name!,
                (form.getValues(props.name!) || 0) + 1,
              );
            }}
            className="bg-ds-button-bg-ds-default default mx-1 h-10 w-10 rounded-lg text-ds-text-primary"
          >
            +
          </button>
        </div>
      </div>
    );
  },
);
InputCounter.displayName = 'InputCounter';

export { Input, InputCounter };
