import { useEffect } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import localFont from 'next/font/local';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { withPasswordProtect } from 'next-password-protect';
import { ThemeProvider } from 'next-themes';
import { cn } from 'src/utils';
import CookieDrawer from 'components/CookieDrawer';
import PageShiftProgressBar from 'components/PageShiftProgressBar';
import isMaintenance from 'utils/isMaintenance';
import isProductionEnv from 'utils/isProductionEnv';
import { CookieAnalytics } from 'lib/cookieAnalytics';
import { GtagScript } from 'lib/gtag';
import Intercom from 'lib/intercom';
import Posthog from 'lib/posthog';
import TwitterPixel from 'lib/twitter-pixel';
import Web3Provider from 'lib/web3-provider';
import WithSnackbar from '../components/WithSnackbar';
import { AppWrapper } from '../store/AppProvider';
import '../theme/globals.css';
declare global {
  interface Window {
    cookie3: {
      trackEvent: (params: {
        category: string;
        action: string;
        name?: string;
        value?: number;
      }) => void;
    };
  }
}
const helvetica = localFont({
  src: '../fonts/HelveticaNowVar.ttf',
  variable: '--font-helvetica'
});
const mono = localFont({
  src: '../fonts/monaspace.woff2',
  variable: '--font-mono'
});
const MyApp = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}: {
  Component: any;
  pageProps: {
    session: any;
  };
}) => {
  const router = useRouter();
  useEffect(() => {
    if (router.pathname !== '/maintenance') isProductionEnv && isMaintenance && router.push('/maintenance');
  }, [router]);
  return <>
      <style jsx global>{`
        html {
          font-family: ${helvetica.style.fontFamily};
        }

        code {
          font-family: ${mono.style.fontFamily};
        }
      `}</style>
      <TwitterPixel />
      <div className={cn(helvetica.variable, mono.variable, 'font-sans text-ds-text-primary antialiased')}>
        <Posthog>
          <ThemeProvider attribute="class">
            <PageShiftProgressBar />

            <WithSnackbar>
              <Web3Provider>
                <AppWrapper>
                  <Intercom>
                    <Component {...pageProps} />
                    <CookieDrawer />
                  </Intercom>
                </AppWrapper>
              </Web3Provider>
            </WithSnackbar>
          </ThemeProvider>
        </Posthog>
      </div>
      {isProductionEnv && <GtagScript />}
      {isProductionEnv && <CookieAnalytics />}
    </>;
};
export default isProductionEnv ? MyApp : withPasswordProtect(MyApp, {
  loginApiUrl: '/api/appLogin',
  checkApiUrl: '/api/appPasswordCheck'
});