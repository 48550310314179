export enum SubscriptionType {
  None = 'None',
  Website = 'Website',
  Basic = 'Basic',
  Growth = 'Growth',
  Enterprise = 'Enterprise',
  GrowthKolIntelligence = 'KOL Intelligence + Growth Cookie3 Analytics',
  KolIntelligence = 'KOL Intelligence - Standard',
  BasicKolIntelligence = 'KOL Intelligence + Basic Cookie3 Analytics',
}
