type Variant =
  | 'primary'
  | 'blue'
  | 'outline'
  | 'disabled'
  | 'text'
  | 'textDark'
  | 'light'
  | 'lightOutlined'
  | 'error'
  | 'dark'
  | 'white';

interface ISpec {
  bgcolor: string;
  color: string;
  borderColor?: string;
}

const disabledSpec: ISpec = {
  bgcolor: 'var(--primary-ds)',
  color: 'var(--text-primary)',
  borderColor: 'var(--border-primary)',
};

const buttonColors: Record<
  Variant,
  {
    bgcolor: string;
    color: string;
    borderColor?: string;
    hover: ISpec;
    active?: ISpec;
    disabled?: ISpec;
  }
> = {
  primary: {
    bgcolor: 'var(--brand3-primary)',
    color: 'white',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'var(--brand3-secondary)',
      color: 'white',
      borderColor: '#383838',
    },
    active: {
      bgcolor: 'var(--brand3-tertiary)',
      color: 'white',
      borderColor: '#383838',
    },
    disabled: disabledSpec,
  },
  blue: {
    bgcolor: 'var(--brand3-primary)',
    color: 'white',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'var(--brand3-secondary)',
      color: 'white',
      borderColor: '#383838',
    },
    active: {
      bgcolor: 'var(--brand3-tertiary)',
      color: 'white',
      borderColor: '#383838',
    },
    disabled: disabledSpec,
  },
  outline: {
    bgcolor: 'transparent',
    color: 'var(--text-primary)',
    borderColor: 'var(--border-primary)',
    hover: {
      bgcolor: 'var(--secondary-ds)',
      color: 'var(--text-primary)',
      borderColor: 'var(--border-primary)',
    },
    active: {
      bgcolor: 'var(--secondary-ds)',
      color: 'var(--text-primary)',
      borderColor: 'var(--border-primary)',
    },
    disabled: disabledSpec,
  },
  white: {
    bgcolor: 'var(--inverted-priamry)',
    color: 'var(--text-inverted-primary)',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'var(--inverted-priamry)',
      color: 'var(--text-inverted-primary)',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'var(--inverted-priamry)',
      color: 'var(--text-inverted-primary)',
      borderColor: 'transparent',
    },
  },
  disabled: {
    bgcolor: 'var(--border-primary)',
    color: 'var(--text-primary)',
    borderColor: 'var(--border-primary)',
    hover: {
      bgcolor: 'var(--border-primary)',
      color: 'var(--text-primary)',
      borderColor: 'var(--border-primary)',
    },
  },
  text: {
    bgcolor: 'transparent',
    color: '#0066FF',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'transparent',
      color: 'blue.500',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'transparent',
      color: 'blue.600',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
  textDark: {
    bgcolor: 'transparent',
    color: 'black',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'transparent',
      color: 'var(--text-secondary)',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'transparent',
      color: 'neutral.800',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
  light: {
    bgcolor: 'blue.100',
    color: 'blue.400',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'blue.50',
      color: 'blue.500',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'blue.100',
      color: 'blue.600',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
  lightOutlined: {
    bgcolor: 'blue.100',
    color: 'blue.400',
    borderColor: 'blue.200',
    hover: {
      bgcolor: 'blue.50',
      color: 'blue.500',
      borderColor: 'blue.200',
    },
    active: {
      bgcolor: 'blue.100',
      color: 'blue.600',
      borderColor: 'blue.200',
    },
    disabled: disabledSpec,
  },
  error: {
    bgcolor: 'red.400',
    color: 'white',
    borderColor: 'red.400',
    hover: {
      bgcolor: 'red.500',
      color: 'white',
      borderColor: 'red.500',
    },
    active: {
      bgcolor: 'red.600',
      color: 'white',
      borderColor: 'red.600',
    },
  },
  dark: {
    bgcolor: 'black',
    color: 'white',
    borderColor: 'transparent',
    hover: {
      bgcolor: 'neutral.800',
      color: 'white',
      borderColor: 'transparent',
    },
    active: {
      bgcolor: 'neutral.800',
      color: 'white',
      borderColor: 'transparent',
    },
    disabled: disabledSpec,
  },
};

export default buttonColors;
