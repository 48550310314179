import React, { ComponentProps } from 'react';
import { Dialog, SxProps, Theme } from '@mui/material';

import Header from 'common/Modal/Header';
import { useIsMobile } from 'utils/hooks';

import ModalActions from './Actions';
import ModalContent from './Content';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  maxWidth?: string;
  isTransparent?: boolean;
  sx?: SxProps<Theme>;
  PaperProps?: ComponentProps<typeof Dialog>['PaperProps'];
  transitionDuration?: number;
}

export default function Modal({
  children,
  isOpen,
  handleClose,
  maxWidth = '640px',
  isTransparent = false,
  sx,
  PaperProps,
  transitionDuration,
}: Props) {
  const isMobile = useIsMobile();

  return (
    <Dialog
      sx={sx}
      PaperProps={{
        ...PaperProps,
        style: {
          background: isTransparent ? 'transparent' : 'var(--primary-ds)',
          boxShadow: 'none',
          borderRadius: '8px',
          width: '100%',
          maxWidth: maxWidth,
          borderColor: `${isTransparent ? 'transparent' : 'var(--primary-ds)'}`,
          borderStyle: 'solid',
          borderWidth: isTransparent ? '1px' : '0px',
          margin: isMobile ? '16px' : '32px',
          overflowX: 'hidden',
          ...PaperProps?.style,
        },
      }}
      open={isOpen}
      onClose={() => handleClose()}
      transitionDuration={transitionDuration}
    >
      {children}
    </Dialog>
  );
}

Modal.Header = Header;
Modal.Content = ModalContent;
Modal.Actions = ModalActions;
