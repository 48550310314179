import { forwardRef, useState } from 'react';
import { PiEnvelopeOpen } from 'react-icons/pi';
import OtpInput from 'react-otp-input';
import { Box, Dialog, Fade, Slide, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import Modal from 'common/Modal';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import Cookie3Logo from 'theme/logo';
import { useDisclosure } from 'utils/hooks';

import ErrorState from './ErrorState';
import LoadingState from './LoadingState';
import VerifyingState from './VerifyingState';

interface Props extends ReturnType<typeof useDisclosure> {
  email: string;
  otpInput: string;
  setOtpInput: (otpInput: string) => void;
  isLoading: boolean;
  error: any;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" in appear ref={ref} {...props} />;
});

export default function VerifyOptInputModal(props: Props) {
  return (
    <Dialog
      key={'verify-otp-input-modal'}
      TransitionComponent={Transition}
      TransitionProps={{
        timeout: 600,
      }}
      PaperProps={{
        style: {
          opacity: props.isOpen ? 1 : 0,
          transition: 'all 0.3s ease',
          background: 'var(--primary-ds)',
          boxShadow: 'none',
          borderColor: 'var(--primary-ds)',
          margin: '16px',
          overflowX: 'hidden',
          width: '440px',
          borderRadius: '24px',
        },
      }}
      open={props.isOpen}
      onClose={props.close}
      sx={{
        // alignSelf: 'flex-start',
        zIndex: 999999999,
        '& .MuiDialog-paper': {
          top: '0px',
          left: '0px',
        },
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          transition: 'all 0.3s ease',
        },
      }}
    >
      <Modal.Header
        sx={{ pb: 0 }}
        title=""
        onClose={() => {
          props.close();
        }}
      />
      <Modal.Content
        sx={{
          height: '300px',
        }}
      >
        <Content {...props} />
      </Modal.Content>
    </Dialog>
  );
}

const Content = (props: Props) => {
  if (props.isLoading) {
    return <LoadingState />;
  }

  if (props.error) {
    return <ErrorState />;
  }

  if (props.otpInput.length === 6) {
    return <VerifyingState />;
  }

  return (
    <Box>
      <VStack
        hcenter
        sx={{
          '& svg': {
            color: 'primary.main',
          },
        }}
      >
        <PiEnvelopeOpen size={60} />
        <Text mt={1} size="lg" textAlign={'center'}>
          Please enter the code sent to your email:
          <br />
          <strong>{props.email}</strong>
        </Text>
      </VStack>
      <Box
        mt={4}
        mb={3}
        sx={{
          ' .otp': {
            border: '1px solid',
            borderColor: 'var(--border-primary)',
            width: '100%',
            fontSize: '20px',
            height: '45px !important',
            textAlign: 'center',
            transition: 'all 0.3s ease',
            '&:focus': {
              borderColor: 'primary.main',
            },
          },
          '& .container': {
            display: 'flex',
            maxWidth: 'unset',
            width: '100%',
            gap: 1,
            px: 0,
            // get 3rd child
            '& > *:nth-child(5)': {
              mr: 2,
            },
            '@media (min-width: 400px)': {
              px: 4,
            },
          },
        }}
      >
        <OtpInput
          value={props.otpInput}
          onChange={(value) => {
            props.setOtpInput(value);
          }}
          numInputs={6}
          containerStyle={'container'}
          renderSeparator={<span></span>}
          inputStyle={'otp'}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: '100%',
                height: '40px',
                borderRadius: '12px',
                boxShadow: 'none',
              }}
            />
          )}
        />
      </Box>
      <HStack vcenter hcenter gap={'6px'}>
        <Text color="tertiary" size="sm" textAlign={'center'}>
          Secured by
        </Text>
        <Box height="12px" width="56px">
          <Cookie3Logo />
        </Box>
      </HStack>
    </Box>
  );
};
