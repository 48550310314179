import Image from 'next/image';

export default function Cookie3Logo() {
  return (
    <svg
      viewBox="0 0 131 34"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.899 12.5014C33.899 7.05809 37.7781 2.82526 43.3855 2.82526C47.4034 2.82526 50.4213 4.95511 51.8497 8.13421L49.3422 9.48048C48.3721 7.18944 46.2422 5.57153 43.3855 5.57153C39.3676 5.57153 36.7825 8.67152 36.7825 12.4984C36.7825 16.3252 39.3975 19.4253 43.3855 19.4253C46.3258 19.4253 48.5079 17.7014 49.4512 15.273L51.9318 16.5939C50.5855 19.9103 47.5109 22.1745 43.3885 22.1745C37.781 22.1745 33.9019 17.9148 33.9019 12.4984L33.8975 12.5014H33.899Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M52.7402 15.0073C52.7402 10.855 55.8134 7.81177 60.0447 7.81177C64.2761 7.81177 67.3492 10.855 67.3492 15.0073C67.3492 19.1595 64.3059 22.1774 60.0447 22.1774C55.7835 22.1774 52.7402 19.1595 52.7402 15.0073ZM60.0447 19.6998C62.6343 19.6998 64.6253 17.7595 64.6253 15.0103C64.6253 12.261 62.6298 10.3207 60.0447 10.3207C57.4596 10.3207 55.4641 12.261 55.4641 15.0103C55.4641 17.7595 57.4865 19.6998 60.0447 19.6998Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M68.1553 15.0073C68.1553 10.855 71.2284 7.81177 75.4597 7.81177C79.6911 7.81177 82.7642 10.855 82.7642 15.0073C82.7642 19.1595 79.7209 22.1774 75.4597 22.1774C71.1986 22.1774 68.1553 19.1595 68.1553 15.0073ZM75.4583 19.6998C78.0478 19.6998 80.0388 17.7595 80.0388 15.0103C80.0388 12.261 78.0433 10.3207 75.4583 10.3207C72.8732 10.3207 70.8777 12.261 70.8777 15.0103C70.8777 17.7595 72.9 19.6998 75.4583 19.6998Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M84.7852 1.99231H87.4777V12.7729H89.6867L93.2971 8.05797H96.5046L91.8672 14.1759L96.6389 21.9386H93.4866L89.3911 15.3624H87.4777V21.9386H84.7852V1.99231Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M99.285 1.82825C100.415 1.82825 101.251 2.66407 101.251 3.79392C101.251 4.92377 100.415 5.75959 99.285 5.75959C98.1552 5.75959 97.3193 4.8984 97.3193 3.79392C97.3193 2.68945 98.1552 1.82825 99.285 1.82825ZM97.9387 8.05362H100.633V21.9342H97.9387V8.05362Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M102.597 15.0073C102.597 10.9118 105.398 7.81177 109.606 7.81177C113.813 7.81177 116.234 11.0192 116.234 14.6834V15.9521H105.319C105.67 18.5938 107.449 19.7536 109.631 19.7536C111.813 19.7536 113.268 18.4073 113.701 17.1938L115.776 18.2983C115.183 19.8356 113.295 22.1774 109.632 22.1774C105.373 22.1774 102.597 19.0521 102.597 15.0073ZM113.434 13.6864C113.407 11.9625 111.953 10.1834 109.58 10.1834C107.532 10.1834 105.861 11.3953 105.403 13.6864H113.434Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M127.692 16.5178C127.692 14.6567 126.346 13.3104 124 13.3104H122.544V10.9119H124C125.913 10.9119 127.1 9.78202 127.1 8.05367C127.1 6.78501 126.047 5.32979 123.892 5.32979C121.601 5.32979 120.441 6.86709 120.255 8.6462L117.964 7.59396C118.367 5.14322 120.255 2.82233 123.922 2.82233C127.589 2.82233 129.906 5.16859 129.906 7.97008C129.906 10.1522 128.694 11.3641 127.697 11.9059C129.476 12.794 130.498 14.3566 130.498 16.4865C130.498 19.5865 128.259 22.176 124.004 22.176C120.203 22.176 118.076 19.6432 117.727 17.4044L120.018 16.3268C120.261 18.1059 121.58 19.6686 123.953 19.6686C126.486 19.6686 127.698 18.3223 127.698 16.5164H127.694L127.692 16.5178Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M69.7398 33.8982L72.5278 26.0266H74.0681L76.8457 33.8982H75.6204L74.889 31.7953H71.6726L70.9413 33.8982H69.7383H69.7398ZM72.0562 30.7042H74.5069L73.2816 27.1624L72.0562 30.7042Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M78.1963 33.8982V26.0266H79.3888L83.5052 31.9639V26.0266H84.6411V33.8982H83.4381L79.3232 27.9714V33.8982H78.1993H78.1963Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M85.9898 33.8982L88.7778 26.0266H90.3181L93.0957 33.8982H91.8704L91.139 31.7953H87.9226L87.1913 33.8982H85.9883H85.9898ZM88.3062 30.7042H90.7569L89.5316 27.1624L88.3062 30.7042Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M94.4463 33.8982V26.0266H95.6045V32.8415H99.3373V33.8982H94.4463Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M101.013 33.8982V30.6714L98.2354 26.0266H99.5742L101.598 29.5236L103.644 26.0266H104.971L102.183 30.6714V33.8982H101.013Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M108.132 33.8982V27.1057H105.612V26.0266H111.83V27.1057H109.311V33.8982H108.13H108.132Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M113.258 33.8982V26.0266H114.428V33.8982H113.258Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M119.904 33.9998C118.749 33.9998 117.802 33.6132 117.06 32.8416C116.318 32.0699 115.946 31.1102 115.946 29.9625C115.946 28.8147 116.318 27.8565 117.06 27.0834C117.802 26.3117 118.751 25.9252 119.904 25.9252C120.721 25.9252 121.437 26.1237 122.052 26.5207C122.667 26.9177 123.128 27.458 123.436 28.1401L122.39 28.7028C122.179 28.2073 121.857 27.8132 121.422 27.5162C120.988 27.2207 120.482 27.0714 119.904 27.0714C119.087 27.0714 118.424 27.3491 117.913 27.9043C117.403 28.4595 117.149 29.1446 117.149 29.9625C117.149 30.7804 117.404 31.4655 117.913 32.0207C118.422 32.5759 119.087 32.852 119.904 32.852C120.504 32.852 121.025 32.6953 121.467 32.3804C121.909 32.0655 122.231 31.6461 122.434 31.1207L123.469 31.6714C123.184 32.3834 122.728 32.949 122.103 33.3699C121.478 33.7893 120.743 33.9998 119.904 33.9998Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        d="M127.55 32.9087C128.143 32.9087 128.582 32.7953 128.867 32.5655C129.152 32.3371 129.293 32.0535 129.293 31.7162C129.293 31.1685 128.911 30.8013 128.147 30.6147L126.91 30.3117C125.568 29.9894 124.896 29.2699 124.896 28.152C124.896 27.4849 125.152 26.9476 125.661 26.5386C126.17 26.1296 126.814 25.9252 127.595 25.9252C128.376 25.9252 128.982 26.1028 129.462 26.4595C129.941 26.8162 130.261 27.2371 130.417 27.7252L129.393 28.2655C129.319 27.9431 129.125 27.6505 128.814 27.3879C128.504 27.1252 128.096 26.9938 127.593 26.9938C127.137 26.9938 126.768 27.0968 126.492 27.3028C126.214 27.5087 126.076 27.7849 126.076 28.1297C126.076 28.6625 126.438 29.0147 127.167 29.1864L128.459 29.4893C129.134 29.655 129.641 29.9267 129.983 30.3043C130.323 30.6834 130.495 31.1461 130.495 31.6923C130.495 32.352 130.234 32.9013 129.713 33.3401C129.192 33.7789 128.462 33.9983 127.526 33.9983C126.723 33.9983 126.061 33.8102 125.535 33.4356C125.01 33.061 124.658 32.6222 124.479 32.1207L125.48 31.558C125.592 31.9476 125.835 32.2699 126.211 32.5252C126.587 32.7804 127.032 32.9073 127.55 32.9073V32.9087Z"
        fill="var(--text-primary)"
        className="letter"
      />
      <path
        id="analytics-shape"
        d="M24.8179 0C24.3254 0 23.9269 0.398512 23.9269 0.891049V19.0537C23.9269 19.5463 23.5283 19.9448 23.0358 19.9448H20.8298C20.3373 19.9448 19.9388 19.5463 19.9388 19.0537V8.86717C19.9388 8.37463 19.5403 7.97612 19.0478 7.97612H16.8433C16.3507 7.97612 15.9522 8.37463 15.9522 8.86717V19.0537C15.9522 19.5463 15.5537 19.9448 15.0612 19.9448H12.8552C12.3627 19.9448 11.9642 19.5463 11.9642 19.0537V12.8552C11.9642 12.3627 11.5657 11.9642 11.0731 11.9642H8.86716C8.37462 11.9642 7.97612 12.3627 7.97612 12.8552V19.0537C7.97612 19.5463 7.57761 19.9448 7.08507 19.9448H0.891044C0.398507 19.9448 0 20.3433 0 20.8358V23.0418C0 23.5343 0.398507 23.9328 0.891044 23.9328H7.08507C7.57761 23.9328 7.97612 23.5343 7.97612 23.0418V20.8358C7.97612 20.3433 8.37462 19.9448 8.86716 19.9448H11.0731C11.5657 19.9448 11.9642 20.3433 11.9642 20.8358V23.0418C11.9642 23.5343 12.3627 23.9328 12.8552 23.9328H15.0612C15.5537 23.9328 15.9522 23.5343 15.9522 23.0418V20.8358C15.9522 20.3433 16.3507 19.9448 16.8433 19.9448H19.0478C19.5403 19.9448 19.9388 20.3433 19.9388 20.8358V23.0418C19.9388 23.5343 20.3373 23.9328 20.8298 23.9328H23.0358C23.5283 23.9328 23.9269 23.5343 23.9269 23.0418V20.8358C23.9269 20.3433 24.3254 19.9448 24.8179 19.9448H27.0239C27.5164 19.9448 27.9149 19.5463 27.9149 19.0537V0.891049C27.9149 0.398512 27.5164 0 27.0239 0H24.8179Z"
        fill="#0066FF"
      />
    </svg>
  );
}
