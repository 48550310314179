import React from 'react';
import { styled } from '@mui/material';

import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import { Label } from 'components/ui/label';

interface Props {
  text: React.ReactNode;
}

export default function RequiredLabel({ text }: Props) {
  return (
    <Label className="text-ds-text-secondary">
      {text}
      <span className="text-ds-text-secondary">*</span>
    </Label>
  );
}

export const RequiredAsterisk = () => {
  return <StyledSpan>*</StyledSpan>;
};

const StyledSpan = styled('span')({
  color: '#FF0000',
  marginRight: '1px',
});
