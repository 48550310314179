import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
          success:
            '!bg-ds-alert-success-bg !p-3 !text-ds-alert-success-text !border-ds-alert-success-border',
          error:
            'bg-ds-alert-error-bg !p-3 text-ds-alert-error-text border-ds-alert-error-border',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
