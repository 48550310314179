import Image from 'next/image';
import { Box, useTheme } from '@mui/material';
import { Provider } from '@supabase/supabase-js';
import { Button } from 'src/shared/ui/button';

import { ProviderSpec } from 'lib/supabase/oauth/config';

interface Props {
  onClick: (provider: Provider) => void;
  loginProviders: ProviderSpec[];
}

export default function SocialLoginButtons({ onClick, loginProviders }: Props) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      columnGap={1.5}
      width="100%"
      overflow="hidden"
    >
      {loginProviders.map((provider) => (
        <Button
          type="button"
          key={provider.name}
          onClick={() => onClick(provider.name)}
          intent="bordered"
          className="mt-2 gap-3"
          style={{
            height: '50px',
            width: '100%',
          }}
        >
          Login with Google
          <Image
            src={provider.icon}
            width={24}
            height={24}
            alt={provider.name}
          />
        </Button>
      ))}
    </Box>
  );
}
