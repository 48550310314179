import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { BaseTextFieldProps, styled, SxProps, Theme } from '@mui/material';
import {
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/shared/ui/form/field';
import { Input } from 'src/shared/ui/form/input';

interface Props<T extends FieldValues> extends BaseTextFieldProps {
  fieldName: Path<T>;
  placeholder?: string;
  control: Control<T>;
  sx?: SxProps<Theme>;
  labelColor?: string;
  label?: React.ReactNode;
  bottomDescription?: React.ReactNode;
  adornment?: React.ReactNode;
  maxLength?: number;
  showCounter?: boolean;
  inputLength?: number;
}

export default function InputField<T extends FieldValues>({
  label,
  fieldName,
  control,
  bottomDescription,
  adornment,
  ...props
}: Props<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: fieldName,
  });

  return (
    <FormField<T>
      control={control}
      name={fieldName}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel htmlFor={fieldName}>{label}</FormLabel>

            <Input
              placeholder={props.placeholder}
              maxLength={props.maxLength}
              {...field}
            />
            <FormDescription>{bottomDescription}</FormDescription>
            <FormMessage />
          </FormItem>
        );
      }}
    ></FormField>
  );
}

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: 'var(--text-secondary)',
  fontSize: '12px',
}));
