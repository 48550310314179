import { ComponentProps, ComponentType } from 'react';
import { IconContext, IconType } from 'react-icons';
import {
  Button as MuiButton,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

import Spinner from '../Spinner';
import buttonColors from './config/colors';
import buttonSizes from './config/sizes';

const commonStyles = {
  boxShadow: 'none',
  minHeight: 'auto',
  height: 'auto',
  borderRadius: '8px',
};

interface Props
  extends Omit<ComponentProps<typeof MuiButton>, 'size' | 'color' | 'variant'> {
  fullWidth?: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  variant?: keyof typeof buttonColors;
  size?: keyof typeof buttonSizes;
  Icon?: ComponentType<Partial<IconType>>;
  iconPlacement?: 'left' | 'right';
  isLoading?: boolean;
  isLabel?: boolean;
}

export default function Button({
  fullWidth,
  children,
  sx,
  variant = 'primary',
  size = 'default',
  Icon,
  iconPlacement = 'left',
  isLoading,
  isLabel = false,
  ...props
}: Props) {
  const { palette } = useTheme();

  const colors = buttonColors[variant]
    ? buttonColors[variant]
    : buttonColors.primary;

  const sizes = buttonSizes[size] ? buttonSizes[size] : buttonSizes.default;

  const IconRenderer = () => {
    if (isLoading)
      return (
        <Spinner
          variant={variant === 'primary' ? 'light' : 'dark'}
          size={sizes.iconSize}
        />
      );

    if (Icon) {
      return <Icon />;
    }

    return null;
  };

  return (
    <IconContext.Provider
      value={{
        color: props.disabled
          ? colors.disabled?.color ?? colors.color
          : colors.color,
        size: sizes.iconSize,
      }}
    >
      <MuiButton
        component={isLabel ? 'label' : 'button'}
        // disableRipple
        {...props}
        TouchRippleProps={{
          style: {
            color: colors.active?.bgcolor,
          },
        }}
        sx={{
          borderWidth: '1px',
          minWidth: 'auto',
          borderStyle: 'solid',
          opacity: props.disabled ? 0.2 : 1,
          transition: 'all 0.05s ease-in-out',
          fontWeight: 500,
          ...commonStyles,
          ...colors,
          ...sizes,
          '&:hover': {
            ...colors.hover,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: colors.color,
            opacity: 1,
            background: '#CCC',
            border: '1px solid #CCC',
            ...colors.disabled,
          },
          '&:active': {
            ...colors.active,
            outlineOffset: '2px',
          },

          '&.Mui-focusVisible': {
            bgcolor: colors.active?.bgcolor,
            transition: 'box-shadow 0.1s ease-in-out',
            boxShadow: `var(--secondary-ds) 0px 0px 0px 2px, ${colors.active?.borderColor} 0px 0px 0px 4px`,
            borderColor: colors.active?.borderColor,
          },
          '& svg': {
            pointerEvents: 'none',
          },
          ...sx,
        }}
        disabled={isLoading || props.disabled}
        fullWidth={fullWidth}
        type={props.type || 'button'}
      >
        <Stack direction="row" gap={1} alignItems="center">
          {iconPlacement === 'left' && <IconRenderer />}
          {children}
          {iconPlacement === 'right' && <IconRenderer />}
        </Stack>
      </MuiButton>
    </IconContext.Provider>
  );
}
