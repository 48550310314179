import { FormEvent } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Box } from '@mui/material';
import { Button } from 'src/shared/ui/button';
import { Input } from 'src/shared/ui/form/input';

import InputField from 'components/Form/InputField';
import { LoginFormData } from 'types/Login/form';

interface Props<T extends FieldValues> {
  onSubmit: (e?: FormEvent) => Promise<void>;
  buttonContent: React.ReactNode;
  disabled: boolean;
  isLoading: boolean;
  control: Control<LoginFormData>;
}

export default function EmailLoginForm({
  onSubmit,
  control,
  buttonContent,
  disabled,
  isLoading,
}: Props<LoginFormData>) {
  return (
    <form onSubmit={onSubmit}>
      <Box mt={4}>
        {!isLoading && (
          <>
            <InputField
              label="Email address"
              control={control}
              fieldName="email"
              disabled={isLoading}
              placeholder="Email address"
            />
          </>
        )}
      </Box>
      <Button
        type="submit"
        intent="brand3"
        className="mt-8 w-full"
        disabled={disabled}
      >
        {buttonContent}
      </Button>
    </form>
  );
}
