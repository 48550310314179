import { PropsWithChildren, useEffect } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useAppStateContext, useIsLoggedIn } from 'src/store/AppProvider';

export default function Intercom(props: PropsWithChildren<{}>) {
  const { user } = useAppStateContext();

  const isLoggedIn = useIsLoggedIn();

  return (
    <IntercomProvider
      appId="nct89gws"
      apiBase="https://api-iam.intercom.io"
      autoBoot={isLoggedIn}
      autoBootProps={{
        email: user?.data?.email,
        name: user?.data?.username || '',
        userId: user?.data?.id || '',
      }}
    >
      {props.children}
    </IntercomProvider>
  );
}
