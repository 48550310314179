import { AppFeature } from 'types/AppFeatureset';
import { SubscriptionType } from 'types/TeamManagement/subscription';

export const getSubscriptionTypeNumericValue = (
  subscriptionType?: SubscriptionType | null,
) => {
  if (!subscriptionType) return -1;

  return SUBSCRIPTION_NUMERIC_VALUES[subscriptionType];
};

const SUBSCRIPTION_NUMERIC_VALUES: { [key in SubscriptionType]: number } = {
  None: 0,
  Website: 1,
  Basic: 2,
  Growth: 3,
  Enterprise: 4,
  [SubscriptionType.KolIntelligence]: 1,
  [SubscriptionType.BasicKolIntelligence]: 2,
  [SubscriptionType.GrowthKolIntelligence]: 3,
};

const SUBSCRIPTION_FEATURESET: { [key in SubscriptionType]: AppFeature[] } = {
  None: [],
  Website: [],
  Basic: [],
  Growth: [],
  Enterprise: [],
  [SubscriptionType.GrowthKolIntelligence]: ['kol-intelligence'],
  [SubscriptionType.KolIntelligence]: ['kol-intelligence'],
  [SubscriptionType.BasicKolIntelligence]: ['kol-intelligence'],
};
