import { PropsWithChildren } from 'react';
import { PiCheck } from 'react-icons/pi';
import YouTube from 'react-youtube';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Stack } from '@mui/material';
import { useIsLoggedIn } from 'src/store/AppProvider';

import Modal from 'common/Modal';
import UnstyledLink from 'common/UnstyledLink';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { useGetTeamSlugs } from 'utils/teamManagement/useGetTeamSlugs';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import RegisterView from '../';
import useRegisterModal from '../hooks/useRegisterModal';

export default function RegisterModal() {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const { teams, isLoading } = useGetTeams();
  const { isOpen } = useRegisterModal();

  const hasMoreThanOneTeam = teams.filter((team) => !team.isDemo).length > 1;
  const isTermsOfServicePage = router.pathname === '/terms';

  const { teamSlug, projectSlug } = useGetTeamSlugs();

  return (
    <Dialog
      open={
        isOpen &&
        !hasMoreThanOneTeam &&
        !isLoading &&
        isLoggedIn &&
        !isTermsOfServicePage
      }
      onOpenChange={() => {}}
    >
      <Stack
        gap={2}
        mx="auto"
        direction={{
          xs: 'column',
          md: 'row',
        }}
      >
        <DialogContent>
          <VStack fullHeight>
            <Box height="100%">
              <RegisterView />
            </Box>
            <Modal.Actions
              sx={{ bgcolor: 'var(--secondary-ds)', px: 5, pb: 3, mt: 'auto' }}
            >
              <Box>
                <Text size="sm" weight="medium">
                  Your free plan access - what&apos;s included?
                </Text>
                <Box>
                  <HStack gap={1} mt={1} vcenter>
                    <Box
                      p={0.5}
                      bgcolor="blue.100"
                      display="flex"
                      borderRadius="50%"
                      color="blue.400"
                    >
                      <PiCheck size={14} strokeWidth={10} />
                    </Box>
                    <Text size="xs" color="secondary">
                      Demo data to help you familiarize yourself with the
                      platform.
                    </Text>
                  </HStack>
                </Box>
              </Box>
            </Modal.Actions>
          </VStack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
}

function Row(props: PropsWithChildren<{}>) {
  return (
    <HStack gap={1} mt={1} vcenter>
      <Box
        p={0.5}
        bgcolor="blue.100"
        display="flex"
        borderRadius="50%"
        color="blue.400"
      >
        <PiCheck size={14} strokeWidth={10} />
      </Box>
      <Text size="xs" color="secondary">
        {props.children}
      </Text>
    </HStack>
  );
}
