import { Box, styled, SxProps, Theme } from '@mui/material';

interface Props {
  children: React.ReactNode;
  withShadow?: boolean;
  sx?: SxProps<Theme>;
}

export default function ModalActions(props: Props) {
  return <StyledButtonPanel {...props} />;
}

const StyledButtonPanel = styled(Box)<{ withShadow?: boolean }>(
  ({ withShadow, theme }) => ({
    padding: '21px 28px 21px 28px',

    boxShadow: withShadow ? '0px -10px 8px 0px #00000005' : 'none',
    transition: 'box-shadow 0.2s ease-in-out',
    borderTop: `1px solid var(--border-primary)`,
  }),
);
