import { AppFeature } from 'types/AppFeatureset';
import { SubscriptionType } from 'types/TeamManagement/subscription';

const SUBSCRIPTION_FEATURESET: { [key in SubscriptionType]: AppFeature[] } = {
  None: [],
  Website: [],
  Basic: [],
  Growth: [],
  Enterprise: [],
  [SubscriptionType.GrowthKolIntelligence]: ['kol-intelligence'],
  [SubscriptionType.KolIntelligence]: ['kol-intelligence'],
  [SubscriptionType.BasicKolIntelligence]: ['kol-intelligence'],
};

export const getSubscriptionFeatureset = (sub: SubscriptionType) => {
  return SUBSCRIPTION_FEATURESET[sub];
};
